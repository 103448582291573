.sticky-parent{
    height: 450vh;
  }
  .sticky{
    position: sticky;
    top: 0px;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .dim{
    display: flex;
    min-width: 60vw;
    height: 100vh;
    align-items: center;
    justify-content: space-around;
  }
  
  .horizontal{
    display: flex;
  }
  @media(max-width:990px){ 
    .dim{
        display: flex;
        min-width: 100vw;
        height: 100vh;
        align-items: center;
        justify-content: space-around;
      }
  }
  @media(max-width:750px){
    
    .dim{
        display: flex;
        min-width:600px;
        height: 100vh;
        align-items: center;
        justify-content: center;
      }
  }
  