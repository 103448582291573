.ourValues{
    background-color: white;
    position: relative;
    transition: 1.2s !important; 
    transition: clip-path 2s ease-out !important;
    clip-path: inset(0 0 0 0);  
    background:url('./aboutImg/ourValueBg.png') ;
    background-size :100% 100% ; 
    background-repeat:no-repeat;
    margin-top:-140px;
 }
 .ourPartner{
   background-color: white;
   position: relative;
   transition: 1.2s !important; 
   transition: clip-path 2s ease-out !important;
   clip-path: inset(0 0 0 0);  
   background:url('./aboutImg/ourFounderBg1.png') ;
   background-size :100% 100%; 
   background-repeat:no-repeat;
   margin-top:-80px;
}
 
.ourValuesMobile{
   display: none;
}
 .ourTeam{ 
    overflow-x: auto;
 }
 .ourTeam > div{
    display: flex;
    flex-wrap: nowrap;
    gap: 20px; 
 }
 
 /* .ourTeam::-webkit-scrollbar { 
    width: 5px;
    height: 5px;
    border-radius: 50px;
  } */
  .ourPartnerCercul{
   position:absolute;
   bottom:-80px;
   width:250px;
   right:0%;
   z-index:1;
}
.aboutFooter{
   background: linear-gradient(#1F2224 50%,#ffffff 0);
   margin-top: -1px;
}
   

 @media(max-width:990px){
    .ourValues{
        background-color: #0169C8; 
     }
     .ourValuesHide{
        display: none;
     }
     .ourPartner{
      background-color: #1F2224; 
      margin-top: 0px;
   }
   .ourPartnerHide{
      /* display: none; */
   }
   .ourPartnerCercul{
      position:absolute;
      bottom:-150px;
      width:250px;
      right:-10%;
      z-index:1;
   }
 }

 @media(max-width:590px){
   .ourValuesPc{
      display: none;
   }
   .ourValuesMobile{
      display: block;
   }
}