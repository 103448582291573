@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
.App {
  text-align: center;
  height: 100vh;  
  box-sizing: border-box;
  width: 100vw !important;
  /* overflow-x: hidden; */
}

 
 
 
 

 
 