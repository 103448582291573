.plasticSlidMain {
  position: relative;
}

.plastic {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.plasticLight {
  color: white;
  background: #73FAC9;
  transition: clip-path 0.3s ease-out !important;
}
.plasticDark{
  background: white;
  color: rgb(0, 0, 0);
}

.plasticSticky {
  position: sticky;
  top: 0;
  z-index: 0;
}

.fixed {
  position: fixed;
  top: 0;
  z-index: -1;
}

.plasticAbsolute {
  position: absolute;
  top: 0;
}


/* -----------slider---------- */
.industrieMain{
  background-color: white;
  position: relative;
  transition: 1.2s !important; 
  color: white;
  /* height: 100vh; */
}
.slider{
  position: relative;
}
.plasticBg{
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #1772C5;
  transition: clip-path 2s ease-out !important;
  
}
 
.industriHide{
  display: block;
  height: 150px;
}
.slider{
   width: 100%;
   /* height:'80vh'; */
}
 .slide img {
   width:250px !important; 
   height: 300px;
   margin: auto;
   border-radius: 50px;
   object-fit: cover;

} 
.slide{
  transform: scale(1);
  transition: .5s;
}

.slide-active  {  
  transform: scale(.9); 
}
.slide .plasticText{
  display: block ;
}

.slide-active .plasticText  {  
display: block;
}

.arrow{
  position: absolute;
  bottom: -25px;
  cursor: pointer;
   
}
.arrow-left{
  left:45%
  
}
.arrow-right{
  right:45%
}

.showText{
 opacity: 0;  
}

.showText.active{ 
 animation: fade-in 5000ms forwards;
}

@keyframes fade-in{
 0%{
     opacity: 0;
 }
 100%{
     opacity: 1;
 }
}
@media(max-width:990px){
  .plasticBg.active-plasticBg{
     clip-path: circle(70.7% at 50% 50%); 
  } 
  .industriHide{
     display: block; 
     height: 50px;
  }
}
@media(max-width:700px){
  .arrow-left{
     left:40%
   }
   .arrow-right{
     right:40%
   }
}
/* @media(max-width:500px){
  .slide img {
     width:250px !important; 
     height: 300px;
     margin: auto;
 
  } 
} */