.researchSlidMain {
  position: relative;
}

.research {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.researchLight {
  color: #000;
  background: #fff;
  transition: clip-path 0.5s ease-out !important;
}

.researchSticky {
  position: sticky;
  top: 0;
  z-index: 0;
}

.fixed {
  position: fixed;
  top: 0;
  z-index: -1;
}

.researchAbsolute {
  position: absolute;
  top: 0;
}
