
.product{
    background-color: white;
    position: relative;
    transition: 1.2s !important; 
    transition: clip-path 2s ease-out !important;
    clip-path: inset(0 0 0 0);  
    background:url('./images/bg.png') ;
    background-size :100% 100%; 
    background-repeat:no-repeat;
   
 }
  
 @media(max-width:990px){
    .product{
        background-color: #0169C8; 
     }
     .productHide{
        display: none;
     }
    }