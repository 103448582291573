.ourServisFQ{
    margin: 30px 0;
    height: 60vh;
    overflow-y: auto;
}
/* .ourServisFQ::-webkit-scrollbar { 
    width: 5px;
    height: 5px;
    border-radius: 50px;
  } */
  .ourservicBenefit{
    background-color: white;
    position: relative;
    transition: 1.2s !important; 
    transition: clip-path 2s ease-out !important;
    clip-path: inset(0 0 0 0);  
    background:url('./img/bg.png') ;
    background-size :100% 100% ; 
    background-repeat:no-repeat !important;
   
 }
 .ourServisFooter{
    background: linear-gradient(#1F2224 50%,#ffffff 0);
    margin-top: -163px;
 }

  
 @media(max-width:990px){
    .ourservicBenefit{
        background-color: #1F2224; 
     }
     .ourservicBenefitHide{
        display: none;
     }
    }
  @media (max-width:770px){
    .ourServisFQ{
        margin: 30px 0;
        height: 80vh;
        overflow-y: auto;
    }
  }