.industrieMain{
   background-color: white;
   position: relative;
   /* transition: 1s !important;  */
   color: white;
   /* height: 100vh; */
}
.slider{
   position: relative;
}
.industriesBg{
   display: block;
   position: absolute;
   width: 100%;
   height: 100%;
   background: #1772C5;
   transition: clip-path 1.2s  ease-out !important;
   clip-path: circle(5% at 50% 50%);; 
}
.industriesBg.active-industriesBg{
   clip-path: circle(100% at 50% 50%); 
}
.industriHide{
   display: block;
   height: 150px;
}
 .slider{
    width: 100%;
    /* height:'80vh'; */
 }
  .slide img {
    width:370px !important; 
    height: 250px;
    margin: auto;

 } 
 .slide{
   transform: scale(.7);
   /* transition: .5s; */
 }

 .slide-active  {  
   transform: scale(1); 
 }
 .slide .industriesText{
   display: none ;
 }

 .slide-active .industriesText  {  
 display: block;
 }
 
 .arrow{
   position: absolute;
   bottom: -25px;
   cursor: pointer;
    
 }
 .arrow-left{
   left:45%
   
 }
 .arrow-right{
   right:45%
 }
 
 .showText{
  opacity: 0;  
 }
 
 .showText.active{ 
  animation: fade-in 5000ms forwards;
 }
 
 @keyframes fade-in{
  0%{
      opacity: 0;
  }
  100%{
      opacity: 1;
  }
}
 @media(max-width:990px){
   .industriesBg.active-industriesBg{
      clip-path: circle(70.7% at 50% 50%); 
   } 
   .industriHide{
      display: block; 
      height: 50px;
   }
 }
 @media(max-width:700px){
   .arrow-left{
      left:40%
    }
    .arrow-right{
      right:40%
    }
 }
 @media(max-width:500px){
   .slide img {
      width:280px !important; 
      height: 180px;
      margin: auto;
  
   } 
 }