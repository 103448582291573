
@import url("https://fonts.googleapis.com/css?family=Roboto:100");

 
.navbarMain{  
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2; 
    height: auto;   
    transition: 300ms; 
}
.navbarMain--hidden{
    transform: translateY(-127px);
    transition: 2000ms; 
}
.decktopNav{
    display: block;
}
.mobileNav{
    display: none;
}
.decktopNav>ul>li{
  cursor: pointer;
}
/* home css */
.clipPath-circle{
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    transition: clip-path 2s ease-out !important;
    clip-path: inset(0 2% 35% 2% round 0px 0px 50px 50px); 
}
.clipPath-circle.active-circle{
    clip-path: inset(0 2% 100% 2% round 0px 0px 50px 50px);  
}
.drowlineDive{
    position: absolute ;
    top: 30%;
}
.drowline{
    width: 100%;
    /* transition: clip-path 2s ease-out !important;  */
    clip-path: inset(0 100% 0 0);
}
.drowline.active-line{
    width: 100%;
    clip-path: inset(0 0 0 0); 
}
/* .topText{
    color: white;
} */
/* .topText2{
    color: white;
} */
.homeTop{
   background-color: white;
   position: relative;
   transition: 1.2s !important;
   height: 100%;
}
.homeHero{
    position: relative;
}
.plasticWord{
    border: 2px solid #73FAC9;
  border-radius :50px;
  color :#73FAC9;
  overflow: hidden; 
  position: relative;
  text-decoration: none;
  transition :.2s transform ease-in-out;
  will-change: transform;
  z-index :0;
  height: fit-content;
  background: white; 
}
 
.plasticWord::after{
background-color: #73FAC9;
border-radius: 50px;
content: '';
display: block;
height: 100%;
width :100%;
position: absolute;
left: 0;
top :0;
transform :translate(-100%, 0) rotate(0deg);
transform-origin: top  bottom;
transition: .2s transform ease-out;
will-change: transform;
z-index:-1;
}

.plasticWord:hover::after{
transform :translate(0, 0);
}
.plasticWord:hover{
border :2px solid transparent;
color:rgb(0, 0, 0);
transform :scale(1.05);
will-change: transform;
}

.campOption{
    position: relative;
    height: 270px;
    border-radius: 50px;
    display: flex;
    align-items: center;
}
.campOptionTitle {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    color: white; 
  }
  
  .campOptionText {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
    height: 270px; 
    padding: 10px 20px;
    text-align: left;
  }
  .campOption:hover .campOptionTitle {
    filter: blur(5px);
   
  }
  
  .campOption:hover .campOptionText {
    opacity: 2; 
  }
  
/* @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 10s linear;
    }
  }  
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */

  .whyOption{
    position: relative;
    width: 250px;
    height: 300px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.whyOptionTitle {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    color: white; 
  }
  
  .whyOptionText {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
    width: 250px;
    height: 300px;
    padding: 10px 20px;
    text-align: left;
  }
  .whyOption:hover .whyOptionTitle {
    filter: blur(5px);
   
  }
  
  .whyOption:hover .whyOptionText {
    opacity: 2; 
  }
  
  .whyPin{
    background-color: white;
    position: relative;
    transition: 1.2s !important;
  }
  .whyPinC{ 
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #73FAC9;
    transition: clip-path 1.2s ease-out !important;
    clip-path: circle(0 at 50% 89%); 
  }
  .whyPinC.active-whyPinC{
    clip-path: circle(55% at 50% 89%); 
    /* clip-path: circle(70.7% at 50% 50%); */
  }
  .whyPinI{
    position: relative;
  }

   .LocoContainer{
    width: 100% !important;
    display: flex; 
    /* grid-template-columns: 100vw 100vw  ;  */
    height: 100vh !important;
    justify-content: baseline;
    gap: 0;
    /* flex-wrap: wrap; */
   }
    
  .panel { 
    min-width: 100vw !important;
    height: 100vh;
    display: inline-flex;
    align-items: center ;
    justify-content: center;
  }
  /* .panel1{
    min-width: 100vw !important;
  }
  .panel2{
    min-width: 50vw !important;
  }
  .panel3{
    min-width: 50vw !important;
  } */
  
  .textLarg{
    font-size:180px ;
    line-height: 100%;
  }

  .sideColorMenu{
    width: 100vh;
    transform: rotate(270deg);
    display: flex;
    position: fixed; 
    flex-flow: row-reverse wrap;
    right: -22.2%;
    top: 50%;
    color: black;
    z-index: 99999;
    /* background: black; */
    transition: 1.5s; 
  }
  .sideMainDiv{ 
    min-width:37px;
    transition: 1.5s; 
   
  }
 

  .sideMainDiv  p{
    display: block ;
    padding: 10px 0;
    font-weight: 700;
    min-width: 150px;
    width: 100%;
    transition: 1.5s; 
    cursor: pointer;
  }
  .sideMainDiv:hover p{
    display: block !important;
  }
 
  .sideMainDiv>Link>p{ 
   
  }
  .sideMainDiv p{ 
    animation: fade-in 1500ms forwards;
   }
   
   @keyframes fade-in{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
  }
  .sticky-parent{
    height:150vh;
  }
  .sticky{
    position: sticky;
    top: 0px;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .dim{
    display: flex;
    min-width: 50vw;
    height: 100vh;
    align-items: center;
    justify-content: space-around;
  }
  
  .horizontal{
    display: flex;
  }
  .svgDrow{
    clip-path: inset(0 0 0 100% );
    transition: clip-path 1500ms ease-out; 
  }
  .svgDrow>img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 50px;
  }
  .svgDrow.active{
    clip-path: inset(0 0 0 0); 
  }
  
  /* .App-logoLab{
    transition: 1.5s; 
  } 
 
@media (prefers-reduced-motion: no-preference) {
  .App-logoLab {
    animation: App-logo-spin infinite 10s linear;
  }
}  

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.text22{
  font-size: 22px;
}
.text18{
  font-size: 18px;
}
.homeOfferMoile{
  display: none;
}

.partner ul {
/* display: flex; */
align-items: center;
justify-content: center;
}
  a{
  color: black; 
  text-decoration:none ;

}
#ourServices > div >p{
  color: gray;
  font-size: 18px;
  text-decoration:none ;

}
#ourServices > div >p:hover{
  color: black;

}

.swal2-container .swal2-center .swal2-backdrop-show{
  z-index: 9999999999 !important;
}
.homeColor{
  background-color: white !important;
  position: relative;
}
 

@media(max-width:990px){

    .decktopNav{
        display: none;
    }
    .mobileNav{
        display: block;
    } 
    .textLarg{
      font-size:130px ;
      line-height: 100%;
    }
    .sideColorMenu{
      display: none;
    }
}
@media(max-width:750px){
    .drowlineDive{
        position: absolute;
        top: 50%;
    }
    .textLarg{
      font-size:60px ;
      line-height: 100%;
    }
    .homeOfferPC{
      display: none;
    }
    .homeOfferMoile{
      display: block;
    }
}