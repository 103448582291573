
.application{
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    
}
/* .application::-webkit-scrollbar { 
    width: 5px;
    height: 5px;
    border-radius: 50px;
  } */
.applicationCart{
    position: relative; 
    display: flex;
    align-items: center;
    height: 350px;
}
.applicationCartTitle {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    color: white; 
  }
  
  .applicationCartText {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
     
    height: 350px; 
    padding: 10px 20px;
    text-align: left;
  }
  .applicationCart:hover .applicationCartTitle {
    filter: blur(5px);
   
  }
  
  .applicationCart:hover .applicationCartText {
    opacity: 2; 
  }
  .applicationFooter{
    background: linear-gradient(#1F2224 50%,#ffffff 0);
 }