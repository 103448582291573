section {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: white;
    z-index: -999px !important;
    transition: 0ms !important;
  }
  
  section .box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  
  section .box.box1 {
    background-color: black;
    /* clip-path: circle(200px at 0 0); */
    clip-path :  inset(0 2% 0% 2% round 0px 0px 50px 50px) ; 
  } 
  section .box.box1 {
    color: white; 
    /* -webkit-text-stroke: 3px #ffffff; */
  }
  .box-2{
    position: relative;
    
  }
  .drowlineDive{
    position: absolute ;
    top: 65%;
    /* background-color: red; */
}
.drowline{
    width: 100%;
    transition: 0 !important; 
    clip-path: inset(0 100% 0 0);
}
 
  @media(max-width:770px){
    .topText{
      font-size: 30px;
    }
  }
  
  
 